window.BlazorScrollToId = function(id, position, behavior) {

    if (position == null) {
        position = "start"
    }

    if (behavior == null) {
        behavior = "smooth"
    }

    const element = document.getElementById(id);

    if (element instanceof HTMLElement) {
        element.scrollIntoView({
            behavior: behavior,
            block: position,
            inline: "nearest"
        });
    }
}
